import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import SiteMetadata from '../componets/SiteMetadata/SiteMetadata';
import Popup from '../componets/Popup/Popup';
import "./globals.css"

export default function Home() {
  return (
    <div>
      <SiteMetadata/>
      <Popup/>
    </div>
  );
}
